const config = {
    apiUrl: "https://backoffice.hausfabrik.at",
    fastApi: "https://fa.hausfabrik.at",
    token: "DF15944DA168722AC0C597DAA",
    apiUrlLocal: "http://localhost/index_dev.php/api",
    apiUrlDebug: "https://backoffice.hausfabrik.at/index_dev.php/api",
    apiLive: "https://backoffice.hausfabrik.at/index_dev.php/api",
    mode: "development",
    fastApiUrl: "https://fa2.hausfabrik.ro",
    gtmId: "GTM-TJDVM5X",
    language: 'de',
    locale: "de_AT",
    domain: "hausfabrik.at",
sandbox: 'false',
amazonStoreId: '',
amazonMerchantId: '',
amazonPublicKeyId: '',
zendeskKey: '1dc2b18f-c3aa-4839-b9ac-871feb527078',
stripePublicKey: "",
    locale_config: [
    	'lang_id',
    	'lang_locale',
   	'lang_url',
    	'country_id',
    	'tax_tax_id',
   	'tax_tax_title',
        'tax_serialized_requirements',
        'store_name',
    	'lang_code',
    	'currency_currency_code',
    	'loading_gif',
    	'currency_currency_symbol',
    	'logo_image',
     	'widerrufsformular_pdf_link',
 	"content_ids",
	"logo_footer",
       "logo_header",
"home_team_img",
	"homepage_products",
	"hero_slider_twelve",
	"vorkasse_info",
	"country_title",
	"ioons_mail_picture",
	"ioons_facebook_url",
	"ioons_instagram_url",
	"ioons_youtube_url",
	"shopvote_shop_id",
        "trusted_shop_id",
        "shopvote_display",
        "trustedshop_display",
	"footer_image_1",
	"footer_image_2",
        "footer_image_3",
        "footer_image_4",
        "phone_regex",
"feature_icon_eight",
	"testimonial_one",
        "site_key",
"advent",
		"advent_bg",
		"advent_gift",
"sal",
		"anpc",
		"display_buttons_ANPC",
		"default_product_image",
"vat_min_length",
"textInCart",
"multiplier",
"guarantee_links",
"content_with_images",
"best_sellers_products_homepage_ids",
"brand_logos_homepage",
"banners_homepage",
"country_visible",
"config_min_price",
		"country_flag",
		"display_country_header",
"is_phone_required"
   ],
keywordGroupString: "badges",
version: '1.0.1.8',
countryDefault: {id: 13, title: 'Österreich', tax_title: '20% Ust.'},
geolocationApiDomain: 'proxy.hausfabrik.at',

};

export const requiredConfigs = ['apiUrl', 'fastApi', 'token', 'language', 'locale'];
export const validateConfig = (config) => {
	const missingConfigs = requiredConfigs.filter(prop => {
		const value = config[prop];
		return value == null || value === '';
	});
	
	return {
		isValid: missingConfigs.length === 0,
		missingConfigs
	};
};

export default config;
